<template>
  <b-button :variant="variant" size="sm" @click="rescan()">
    <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
    <b-icon v-if="done && !loading  && variant === 'success'" icon="check"></b-icon>
    <b-icon v-if="done && !loading && variant === 'danger'" icon="exclamation-octagon"></b-icon>
    <b-icon v-if="!done && !loading" icon="upc-scan"></b-icon> {{ $t("Rescan") }}
  </b-button>
</template>
<script>
import http from "@/httpclient";

export default {
  props: {
    organization: {
      type: Number
    },
    url: {
      type: String
    }
  },
  data: function () {
    return {
      loading: false,
      variant: "secondary",
      done: false,
    }
  },
  methods: {
    rescan: function () {
      this.variant = "secondary";
      this.loading = true;

      http.post(`/portfolio/rescan/`,
        {
          'url': this.url,
          'organization_id': this.organization
        }).then((response) => {
        if (response.status == 200){
          const data = response.data;

          if (data.success === true) {
            this.variant = "success";
          } else {
            this.variant = "danger";
          }
        } else {
          this.variant = "danger";
          console.error(`Error during rescan: ${response}`)
        }
        this.loading = false;
        this.done = true;
      });
    },
  }
}
</script>
<i18n>
{
  "en": {
    "Rescan": "Rescan"
  },
  "nl": {
    "Rescan": "Herscannen"
  }
}
</i18n>

<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro") }}</p>

    <template v-if="show_retry">
      <b-button size="sm" class="m-2" type="submit" variant="secondary" @click="get_portfolio_organizations">
        <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
        Retry retrieval
      </b-button>
    </template>

    <a href="https://internetcleanup.foundation/basisbeveiliging/meetbeleid/comply-or-explain-handleiding-voor-een-goede-verklaring/" target="_blank">
      {{ $t("Guidelines") }} <b-icon icon="folder-symlink" />
    </a>
    <br><br>

    <b-tabs content-class="mt-3">
      <template v-for="organization in organizations">
        <b-tab :title="organization.name" :key="organization.id" lazy>

          <b-tabs>
            <b-tab lazy>
              <template #title>
                <b-icon icon="card-checklist" variant="success"></b-icon>
                {{ $t("todo") }}
              </template>
              <issue_table :organization="organization" :is_dead="false" :not_resolvable="false" mode="list_issues_todolist"/>
            </b-tab>

            <b-tab lazy>

              <template #title>
                <b-icon icon="hdd-network" variant="success"></b-icon>
                {{ $t("resolving") }}
              </template>

              <b-tabs>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="check" variant="success"></b-icon>
                    {{ $t("not_is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="false" :not_resolvable="false"/>
                </b-tab>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="moon" variant="secondary"></b-icon>
                    {{ $t("is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="true" :not_resolvable="false"/>
                </b-tab>
              </b-tabs>


            </b-tab>
            <b-tab lazy>
              <template #title>
                <b-icon icon="wifi-off" variant="danger"></b-icon>
                {{ $t("not_resolving") }}
              </template>

              <b-tabs>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="check" variant="secondary"></b-icon>
                    {{ $t("archived_not_is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="false" :not_resolvable="true"/>
                </b-tab>
                <b-tab lazy>
                  <template #title>
                    <b-icon icon="moon" variant="secondary"></b-icon>
                    {{ $t("archived_is_dead") }}
                  </template>
                  <issue_table :organization="organization" :is_dead="true" :not_resolvable="true"/>
                </b-tab>
              </b-tabs>

            </b-tab>


          </b-tabs>

        </b-tab>
      </template>
    </b-tabs>

  </div>
</template>

<script>

import issue_table from "@/components/issues/issue_table";

export default {
  components: {
    issue_table
  },
  data: function () {
    return {
      organizations: [],
      loading: false,
      show_retry: false,
    }
  },
  mounted: function () {
    this.get_portfolio_organizations();
  },
  methods: {
    get_portfolio_organizations: function () {
      this.loading = true;
      this.server_response = {};
      this.$http.get('/portfolio/list_organizations/').then(data => {
        this.organizations = data.data;
        this.loading = false;
        this.show_retry = false;
      }).catch(() => {
        this.show_retry = true;
      });
    },
  },
}
</script>
<i18n>
{
  "en": {
    "title": "Findings",
    "intro": "Below are all current findings with an impact (aka 'known issues'). Findings disappear automatically when they are rescanned and fixed. Persistent issues that contrast a design decision can be explained publicly.",
    "Guidelines": "Use this manual to provide great explanations (Dutch)",
    "resolving": "Resolving Domains",
    "not_resolving": "Not Resolving Domains",
    "not_is_dead": "Alive and in report",
    "is_dead": "(temporary) Archived findings",
    "archived_not_is_dead": "In report when the domain resolves",
    "archived_is_dead": "(temporary) Archived findings even if domain resolves",
    "todo": "To Do List"
  },
  "nl": {
    "title": "Bevindingen",
    "intro": "Onderstaand overzicht toont alle gemeten (beveiligings)problemen van het portfolio. Wanneer deze zijn opgelost en herscand worden ze automatisch uit de lijst gehaald. Wanneer iets 'by design' afwijkt van een norm, kan hier publiek uitleg over worden gegegeven.",
    "Guidelines": "Gebruik deze handleiding om goede verklaringen te geven",
    "resolving": "Bereikbare domeinen",
    "not_resolving": "Niet bereikbare domeinen",
    "not_is_dead": "Bevindingen in rapport",
    "is_dead": "(tijdelijk) Gearchiveerde bevindingen",
    "archived_not_is_dead": "In rapport wanneer het domein bereikbaar wordt",
    "archived_is_dead": "(tijdelijk) Gearchiveerde bevindingen zelfs wanneer het domein bereikbaar wordt",
    "todo": "Nog Openstaand"
  }
}
</i18n>
